import * as React from "react";
export default (function (props) {
    return React.createElement("div", { className: "columns is-vcentered is-centered is-full-height" },
        React.createElement("div", { className: "column is-two-fifths" },
            React.createElement("div", { className: "box" },
                React.createElement("h1", { className: "title" }, "Welcome"),
                React.createElement("div", { className: "block" },
                    "Thanks for stopping by! The content for my website is still under construction. Please check back again soon; until then, check out my ",
                    React.createElement("strong", null, "LinkedIn"),
                    " and ",
                    React.createElement("strong", null, "Github!")),
                React.createElement("div", { className: "block" },
                    React.createElement("strong", null, "Tanner D. Mckenney")),
                React.createElement("div", { className: "buttons has-addons is-centered" },
                    React.createElement("a", { href: "https://github.com/tdmckenney0", className: "button" }, "Github"),
                    React.createElement("a", { href: "https://www.linkedin.com/in/tmckenney7", className: "button" }, "LinkedIn"),
                    React.createElement("a", { href: "https://github.com/tdmckenney0/tmckenney.com", className: "button" }, "Source")))));
});
