import './style/main.scss';
import * as React from 'react';
import { render } from 'react-dom';
import * as THREE from 'three';
import App from './ui/App';
/**
 * Sizes
 */
var sizes = { width: 0, height: 0 };
sizes.width = window.innerWidth;
sizes.height = window.innerHeight;
window.addEventListener('resize', function () {
    // Save sizes
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;
    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();
    // Update renderer
    renderer.setSize(sizes.width, sizes.height);
});
/**
 * Environments
 */
// Scene
var scene = new THREE.Scene();
scene.background = new THREE.Color(0x000000);
// Camera
var camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100);
camera.position.z = 20;
camera.position.x = 0;
camera.rotation.x = 0;
scene.add(camera);
var directionalLight = new THREE.DirectionalLight(0x0000FF, 0.5);
directionalLight.position.set(0, 0, 1);
scene.add(directionalLight);
var light = new THREE.AmbientLight(0x333344, 1.0);
scene.add(light);
var skyGeo = new THREE.SphereGeometry(50, 25, 25);
// Renderer
var renderer = new THREE.WebGLRenderer({
    canvas: document.querySelector('.webgl')
});
renderer.setPixelRatio(window.devicePixelRatio);
renderer.setSize(sizes.width, sizes.height);
var tasks = {};
var addTask = function (task, id) {
    tasks[id] = task;
};
var removeTask = function (id) {
    delete tasks[id];
};
/**
 * Loop
 */
var loop = function () {
    for (var task in tasks) {
        tasks[task]();
    }
    // Render
    renderer.render(scene, camera);
    // Keep looping
    window.requestAnimationFrame(loop);
};
loop();
// Rotate the Sky geometry
addTask(function () {
    skyGeo.rotateY(0.00025);
}, "rotateBackground");
var background = new THREE.TextureLoader().load("backgrounds/hexagon.png");
background.wrapS = THREE.RepeatWrapping;
background.wrapT = THREE.RepeatWrapping;
background.repeat.set(15, 15);
background.anisotropy = 64;
var material = new THREE.MeshPhongMaterial({
    map: background,
});
var sky = new THREE.Mesh(skyGeo, material);
sky.material.side = THREE.BackSide;
scene.add(sky);
render(React.createElement(App, null), document.getElementById('app'));
